import React, { FC, useMemo } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { useBidPreview } from 'src/models/bids/BidPreview/hooks';
import { BlockEmpty, TenderBox, InfoBox, PreviousProcurements } from 'src/shared';
import { UserName } from 'src/common';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { isFieldString, TenderBlockSize, TenderBoxMode } from 'src/models/procurements/Tender/types';
import { Translations } from 'src/lib/I18nService/types';
import { isAddition } from 'src/models/procurements/Tender/helpers';
import { BidItemType } from '@tendium/prom-types/tender';

export const BidPreviewInfo: FC = () => {
  const { t } = useTranslation();
  const { t: tenderT } = useTranslation(Translations.procurements);

  const { data } = useBidPreview();
  const origin = data?.origin;
  const bidId = data?.id;
  const isProcurementItemType = data?.origin.originType === BidItemType.Procurement;

  const descBoxes = useMemo(() => origin?.description, [origin]);
  const locationBoxes = useMemo(
    () =>
      origin?.locationBoxes
        ? origin?.placeOfPerformanceNutsCode
          ? [...origin.locationBoxes, origin.placeOfPerformanceNutsCode]
          : origin.locationBoxes
        : origin?.placeOfPerformanceNutsCode
        ? [origin.placeOfPerformanceNutsCode]
        : [],
    [origin]
  );
  const estimationValueBox = useMemo(() => origin?.contractValueBoxes[0], [origin]);
  const deadlineBox = useMemo(() => origin?.deadline[0], [origin]);

  const startBox = useMemo(() => origin?.contractStartAndEndStart || undefined, [origin]);

  const durationBoxes = origin?.contractDurationBoxes;
  const renewalBoxes = origin?.contractRenewalBoxes;
  const procedureBoxes = origin?.typeOfProcedureBoxes;
  const publishedBox = origin?.availableDate;
  const questionBoxes = origin?.deadlineOfClarificationQuestions;
  const customBoxes = origin?.customBoxes;

  const isCustomBidFieldsFeature = useFeatureFlag(FeatureFlag.CustomBidFields);
  const isPreviousProcurementsFeature = useFeatureFlag(FeatureFlag.Analytics_Previous_Procurements);
  const isEditableBidFeature = !!useFeatureFlag(FeatureFlag.BiddingWorkflow_Preview);

  if (!data) return null;

  return bidId ? (
    <>
      <div className={styles.block}>
        <InfoBox
          title={t('BidSpaces.creationDateOfBid')}
          className={styles.info}
          value={
            data.createdAt ? (
              <>
                <div className={styles.date}>
                  {t('Common.Format.tableDateFormatForShortMonths', { date: new Date(data.createdAt) })}
                </div>
                {data.createdBy?.email && <UserName user={data.createdBy} />}
              </>
            ) : (
              <BlockEmpty />
            )
          }
        />
      </div>
      <div className={styles.boxes}>
        <TenderBox
          boxes={descBoxes}
          title={tenderT('Short description')}
          as={TenderBoxMode.Collection}
          actions={false}
          isEditable={isEditableBidFeature}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.boxes}>
          <TenderBox
            boxes={locationBoxes}
            title={tenderT('Place of performance')}
            as={TenderBoxMode.Collection}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
          />
          <TenderBox
            box={publishedBox}
            title={tenderT('Publication date')}
            as={TenderBoxMode.Custom}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
            config={{ date: { isCountdown: true } }}
          />
          <TenderBox
            box={deadlineBox}
            title={tenderT('Submission deadline', { context: 'short' })}
            as={TenderBoxMode.Custom}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
            config={{ date: { isCountdown: true } }}
            isFirstField
            isInfo={isAddition(origin?.deadline)}
          />
          <TenderBox
            box={estimationValueBox}
            title={tenderT('Estimated contract value')}
            as={TenderBoxMode.Custom}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
            field={estimationValueBox?.fields.find(field => field.name === 'Estimated contract value')}
            isInfo={isAddition(origin?.contractValueBoxes)}
          />
          <TenderBox
            boxes={questionBoxes}
            title={tenderT('Deadline of clarification questions')}
            as={TenderBoxMode.Collection}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
            config={{ date: { isCountdown: true } }}
          />

          <TenderBox
            boxes={durationBoxes}
            title={tenderT('Contract duration')}
            as={TenderBoxMode.Collection}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
            isFirstField
            isInfo={isAddition(origin?.contractDurationBoxes)}
          />
          <TenderBox
            box={startBox}
            title={tenderT('Contract start date')}
            as={TenderBoxMode.Custom}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
            isFirstField
            isInfo={startBox && isAddition([startBox])}
          />
          <TenderBox
            boxes={renewalBoxes}
            title={tenderT('Contract renewal')}
            as={TenderBoxMode.Collection}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
            isFirstField
            isInfo={isAddition(origin?.contractRenewalBoxes)}
          />

          <TenderBox
            boxes={procedureBoxes}
            title={tenderT('Type of procedure')}
            as={TenderBoxMode.Collection}
            size={TenderBlockSize.Half}
            actions={false}
            isEditable={isEditableBidFeature}
          />
          {isCustomBidFieldsFeature &&
            customBoxes &&
            customBoxes.map(box => (
              <TenderBox
                title={box.title}
                isEditable={isEditableBidFeature}
                actions={false}
                boxes={[box]}
                as={TenderBoxMode.Collection}
                key={box.id}
                size={isFieldString(box.firstField) ? TenderBlockSize.Full : TenderBlockSize.Half}
              />
            ))}
          {!!isProcurementItemType && !!isPreviousProcurementsFeature && (
            <PreviousProcurements procurementId={data.origin.id} />
          )}
        </div>
      </div>
    </>
  ) : (
    <p className={styles.block}>{t('BidSpaces.noBidNote')}</p>
  );
};

export default BidPreviewInfo;
