import React, { JSX } from 'react';
import { scaleLinear, scaleBand } from '@visx/scale';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { CHART_DEFAULT_MARGIN, GRID_COLOR, CHART_TEXT_COLOR, XYChartProps } from '../types';
import { XYChartContext } from './context';
import Legend from '../Legend';

export function XYChart(props: XYChartProps): JSX.Element | null {
  const {
    width,
    height,
    xDomain,
    yDomain,
    colorScale,
    formatX,
    formatY,
    formatLegend,
    margin = CHART_DEFAULT_MARGIN,
    children
  } = props;
  // scales
  const xScale = scaleBand<string>({
    domain: xDomain,
    padding: 0.2
  });
  const yScale = scaleLinear<number>({
    domain: [yDomain[0], yDomain[1] * 1.2],
    nice: true
  });
  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  xScale.rangeRound([0, xMax]);
  yScale.rangeRound([yMax, 0]);

  return (
    <>
      <Legend colorScale={colorScale} labelFormat={formatLegend} />
      <svg width={width} height={height}>
        <GridRows
          scale={yScale}
          width={xMax - 10}
          height={yMax}
          left={margin.left + 10}
          top={margin.top}
          strokeDasharray={'4,4'}
          color={GRID_COLOR}
        />
        <Group top={margin.top} left={margin.left}>
          <XYChartContext.Provider value={{ xScale, yScale, colorScale, xMax, yMax, margin }}>
            {children}
          </XYChartContext.Provider>
          <AxisLeft
            scale={yScale}
            hideTicks
            stroke={CHART_TEXT_COLOR}
            tickStroke={CHART_TEXT_COLOR}
            tickFormat={formatY}
            tickLabelProps={{
              fill: CHART_TEXT_COLOR,
              fontSize: 14,
              textAnchor: 'end',
              verticalAnchor: 'middle'
            }}
          />
          <AxisBottom
            top={yMax}
            scale={xScale}
            numTicks={xDomain.length}
            tickFormat={formatX}
            stroke={CHART_TEXT_COLOR}
            tickStroke={CHART_TEXT_COLOR}
            tickLabelProps={{
              fill: CHART_TEXT_COLOR,
              fontSize: 14,
              textAnchor: 'end',
              angle: -45
            }}
          />
        </Group>
      </svg>
    </>
  );
}

export default XYChart;
