import {
  MutationTuple,
  useMutation,
  MutationUpdaterFn,
  useQuery,
  QueryResult,
  LazyQueryResultTuple,
  useLazyQuery
} from '@apollo/client';
import { useContext, useMemo, Dispatch, SetStateAction, useCallback, useState } from 'react';
import {
  IApiTenderBox,
  IApiTenderRequest,
  IApiTenderResponse,
  IApiUpdatedTenderResponse,
  IApiUpdatedTenderRequest,
  IApiTender,
  IApiTenderBidResponse,
  ITenderBox,
  IApiBoxField,
  IBoxWithComments,
  TenderKeys
} from './types';
import {
  GET_FULL_TENDER,
  ADD_TENDER_BOX,
  REMOVE_TENDER_BOX,
  GET_UPDATED_TENDER,
  GET_TENDER_BID,
  GET_TENDER_FILE,
  GET_ZIP
} from './queries';
import { TenderContextValue, TenderContext, UpdateCurrentTenderBoxContext, CurrentTenderBoxContext } from './context';
import { Reference } from '@apollo/client/core';
import { FeatureFlag, useFeatureFlag } from 'src/helpers/featureFlag';
import { BoxSpecId, OverviewQp } from '../types';
import { clearWsStatsCache } from 'src/models/workspace/hooks';
import { useQueryParams } from 'src/helpers/history';
import { IApiComment } from 'src/models/comments/types';
import { isString } from 'src/helpers';
import { useParams } from 'react-router-dom';
import { downloadFile, splitFileExt } from 'src/helpers/files';
import { isConvertableFile, isPreviewableFile } from '../helpers';

export function useApiTender(id?: string): Pick<
  QueryResult<IApiUpdatedTenderResponse, IApiUpdatedTenderRequest>,
  'loading' | 'refetch'
> & {
  data?: IApiTender;
} {
  const isEditableTender = useFeatureFlag(FeatureFlag.EditableDetailspage);
  const isGPTSummaryFeature = useFeatureFlag(FeatureFlag.GPT_Summary);

  const { data, loading: dataLoading } = useQuery<IApiTenderBidResponse, IApiTenderRequest>(GET_TENDER_BID, {
    variables: {
      id: id ?? '',
      includeSummary: !!isGPTSummaryFeature
    },
    skip: !isEditableTender || !id
  });
  const bidId = useMemo(() => (!!data?.getTender.bids.length ? data.getTender.bids[0].id : undefined), [data]);
  const skipGetTender = (!data?.getTender || !!bidId) && !!isEditableTender;
  const { data: tenderData, loading: tenderLoading } = useQuery<IApiTenderResponse, IApiTenderRequest>(
    GET_FULL_TENDER,
    {
      variables: {
        id: id ?? '',
        includeSummary: !!isGPTSummaryFeature
      },
      skip: skipGetTender || !id
    }
  );
  const {
    data: updTenderData,
    loading: updTenderLoading,
    refetch
  } = useQuery<IApiUpdatedTenderResponse, IApiUpdatedTenderRequest>(GET_UPDATED_TENDER, {
    variables: {
      id: id ?? '',
      bidId: bidId ?? '',
      includeSummary: !!isGPTSummaryFeature
    },
    skip: !isEditableTender || (!bidId && !id)
  });

  return useMemo(
    () => ({
      data: skipGetTender ? updTenderData?.getUpdatedTender : tenderData?.getTender,
      loading: dataLoading || (skipGetTender ? updTenderLoading : tenderLoading),
      refetch
    }),
    [dataLoading, refetch, skipGetTender, tenderData, tenderLoading, updTenderData, updTenderLoading]
  );
}

export function useTenderData(): TenderContextValue {
  return useContext(TenderContext);
}

export function useCurrentTenderBoxId(): string | null {
  return useContext(CurrentTenderBoxContext);
}

export function useUpdateCurrentTenderBox(): Dispatch<SetStateAction<string | null>> | null {
  return useContext(UpdateCurrentTenderBoxContext);
}

export function useCurrentTenderBox(): IBoxWithComments | null {
  const { data: model } = useTenderData();
  const boxId = useCurrentTenderBoxId();

  return useMemo(() => {
    return (boxId && model && model.currentBox(boxId)) || null;
  }, [boxId, model]);
}

export function useEvaluationModelBox(): ITenderBox | undefined {
  const { data: model } = useTenderData();

  return useMemo(() => {
    const cate = (model?.cats || []).find(cate => cate.id === `BIDDING_PROCESS`);
    const subCate = (cate?.subCats || []).find(subCat => subCat.id === `BIDDING_PROCESS.EVALUATION_PROCESS`);
    const box = (subCate?.boxes || []).find(box => box.specificationId === BoxSpecId.EVALUATION_MODEL_3);
    return box;
  }, [model]);
}

type TenderBoxFieldInput = IApiBoxField;
interface IAddCustomTenderBoxInput {
  title: string;
  fields: TenderBoxFieldInput[];
  category: string;
  specificationId?: BoxSpecId | string;
}
interface IAddTenderBoxInput {
  id: string;
  bidId: string;
  box: IAddCustomTenderBoxInput;
}

export interface IAddTenderBoxResponse {
  __typename: 'Mutation';
  addTenderBox: IApiTenderBox & {
    __typename: 'TenderBox';
  };
}

export function useAddTenderBox(): MutationTuple<IAddTenderBoxResponse, IAddTenderBoxInput> {
  return useMutation<IAddTenderBoxResponse, IAddTenderBoxInput>(ADD_TENDER_BOX);
}

interface ITenderBoxToRemoveInput {
  specificationId: string;
  boxId: string;
}

interface IRemoveTenderBoxInput {
  id: string;
  bidId: string;
  box: ITenderBoxToRemoveInput;
}

interface IRemoveTenderBoxResponse {
  __typename: 'Mutation';
  removeTenderBox: boolean;
}

export function useRemoveTenderBox(): MutationTuple<IRemoveTenderBoxResponse, IRemoveTenderBoxInput> {
  return useMutation<IRemoveTenderBoxResponse, IRemoveTenderBoxInput>(REMOVE_TENDER_BOX);
}

export function updateCacheAddTenderBox(
  itemId: string,
  key?: TenderKeys,
  bidId?: string
): MutationUpdaterFn<IAddTenderBoxResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }

    const newBoxId = data.addTenderBox.id;
    if (key) {
      const generalRef = cache.identify({
        __typename: 'General',
        id: itemId
      });
      const timelineRef = cache.identify({
        __typename: 'TimelineRefactored',
        id: itemId
      });
      cache.modify({
        id: timelineRef,
        fields: {
          [key]: (keyObj: Reference | Reference[], { toReference }) => {
            const newBoxRef = toReference({ __typename: 'TenderBox', id: newBoxId });
            return Array.isArray(keyObj) ? [newBoxRef] : newBoxRef;
          }
        }
      });
      cache.modify({
        id: generalRef,
        fields: {
          [key]: (keyObj: Reference | Reference[], { toReference }) => {
            const newBoxRef = toReference({ __typename: 'TenderBox', id: newBoxId });
            return Array.isArray(keyObj) ? [newBoxRef] : newBoxRef;
          }
        }
      });
    } else {
      const tenderRef = cache.identify({
        __typename: 'Tender',
        id: itemId
      });
      cache.modify({
        id: tenderRef,
        fields: {
          boxes(existingBoxesRefs: Reference[], { readField, toReference }) {
            return existingBoxesRefs.some(ref => readField('id', ref) === newBoxId)
              ? existingBoxesRefs
              : [...existingBoxesRefs, toReference({ __typename: 'TenderBox', id: newBoxId })];
          }
        }
      });
    }

    let wsId;
    bidId &&
      cache.modify({
        id: cache.identify({
          __typename: 'BidV2',
          id: bidId
        }),
        fields: {
          workspace(wsRef, { readField }) {
            wsId = readField('id', wsRef);
            return wsRef;
          }
        }
      });
    wsId && clearWsStatsCache(cache, wsId);
  };
}

export function updateCacheRemoveTenderBox(
  tenderId: string,
  boxId: string
): MutationUpdaterFn<IRemoveTenderBoxResponse> {
  return (cache, { data }) => {
    if (!data) {
      return null;
    }
    const tenderRef = cache.identify({
      __typename: 'Tender',
      id: tenderId
    });
    cache.modify({
      id: tenderRef,
      fields: {
        boxes(existingBoxesRefs: Reference[], { readField }) {
          return existingBoxesRefs.filter(ref => boxId !== readField('id', ref));
        }
      }
    });
  };
}

export function useQPComment(): IApiComment | null {
  const { data: procurement } = useTenderData();
  const qp = useQueryParams();
  const commentId = qp[OverviewQp.CommentId];

  const existingComment = useMemo(() => {
    return (
      (isString(commentId) && procurement && procurement.flatComments.find(comment => comment.id === commentId)) || null
    );
  }, [commentId, procurement]);

  return existingComment || null;
}

interface GetFileRequest {
  procurementId?: string;
  targetPath?: string | null;
  skipConversion?: boolean | null;
}

export interface GetFileResponse {
  readonly getFile: {
    url: string;
    conversionResult?: boolean;
  };
}

export function useFileUrl(input: GetFileRequest): QueryResult<GetFileResponse, GetFileRequest> {
  return useQuery<GetFileResponse, GetFileRequest>(GET_TENDER_FILE, {
    variables: {
      procurementId: input.procurementId,
      targetPath: input.targetPath,
      skipConversion: input.skipConversion ?? true
    },
    skip: !input.procurementId || !input.targetPath
  });
}

export function useLazyFileUrl(): LazyQueryResultTuple<GetFileResponse, GetFileRequest> {
  return useLazyQuery<GetFileResponse, GetFileRequest>(GET_TENDER_FILE);
}

export function useDownloadFile(id?: string): {
  getFile: (targetPath: string) => void;
  loading: boolean;
} {
  const { id: paramId } = useParams<{ id: string }>();
  const [getFileLazy, { loading }] = useLazyFileUrl();

  const procurementId = id || paramId;

  const getFile = useCallback(
    (targetPath: string) => {
      return getFileLazy({ variables: { procurementId, targetPath, skipConversion: true } }).then(res => {
        if (res.error) {
          return;
        }
        const url = res.data?.getFile?.url;
        if (url) {
          downloadFile(url, targetPath);
        }
      });
    },
    [getFileLazy, procurementId]
  );

  return useMemo(() => ({ getFile, loading }), [getFile, loading]);
}
export function usePreviewFile(id?: string): {
  previewFile: (targetPath: string) => boolean;
  closePreview: () => void;
  url: string;
  loading: boolean;
} {
  const { id: paramId } = useParams<{ id: string }>();
  const procurementId = id || paramId;
  const [getFileLazy, { loading }] = useLazyFileUrl();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const openPreview = useCallback((url: string) => {
    setPreviewUrl(url);
    setPreviewVisible(true);
  }, []);
  const closePreview = useCallback(() => {
    setPreviewUrl('');
    setPreviewVisible(false);
  }, []);

  const previewFile = useCallback(
    (targetPath: string) => {
      const [, fileExt] = splitFileExt(targetPath);
      const isPreviewable = isPreviewableFile(fileExt);
      const isConvertable = isConvertableFile(fileExt);
      getFileLazy({ variables: { procurementId, targetPath, skipConversion: !isConvertable } }).then(res => {
        if (res.error) {
          return;
        }
        if (!res.data?.getFile?.url) return;
        const { conversionResult, url } = res.data.getFile;
        if (!isPreviewable || (isConvertable && !conversionResult)) {
          downloadFile(url, targetPath);
          return;
        }
        openPreview(url);
      });
      return isPreviewable;
    },
    [getFileLazy, openPreview, procurementId]
  );

  return useMemo(
    () => ({
      url: previewVisible ? previewUrl : '',
      loading,
      previewFile,
      closePreview
    }),
    [closePreview, loading, previewFile, previewUrl, previewVisible]
  );
}

export function useLoadTenderZipFile(): LazyQueryResultTuple<
  { getZip: string },
  { procurementId: string; fileName: string }
> {
  return useLazyQuery<{ getZip: string }, { procurementId: string; fileName: string }>(GET_ZIP);
}

export function useDownloadTenderZipFile(): { getFile: (fileName: string) => void; loading: boolean } {
  const { id: procurementId } = useParams<{ id?: string }>();
  const [getZipFileLazy, { loading }] = useLoadTenderZipFile();

  const getFile = useCallback(
    (fileName: string) => {
      procurementId &&
        getZipFileLazy({ variables: { procurementId, fileName } }).then(data => {
          const url = data.data?.getZip;
          if (!url) return;

          downloadFile(url, `${fileName}.zip`);
        });
    },
    [getZipFileLazy, procurementId]
  );

  return {
    getFile,
    loading
  };
}
